<title>Himorna - Results</title>
<template>
  <main class="main">
    <MyHeader />
    <section class="result">
      <div class="wrap">
        <div class="result-header">
          <h4 v-if="((getTable || results) && getTable.table.data.length > 0) ||
            results.table.data.length > 0 > 0
            ">
            {{ results.title || getTable.title }}
          </h4>
          <h4 v-else>Nothing found, please try again</h4>
          <span class="top-menu" v-if="((getTable || results) && getTable.table.data.length > 0) ||
            results.table.data.length > 0
            ">
            <a @click="download" class="button violet download">
              <p>Download tab delimited</p>
            </a>
            <dropdown-menu :direction="right">
              <template #trigger>
                <a class="button violet">Go to RNA-Chrom DB</a>
              </template>
              <template #body>
                <div class="menu-body" v-if="selectedRow">
                  <a class="button violet"
                    :href="`https://rnachrom2.bioinf.fbb.msu.ru/basic_graphical_summary?name=${selectedRow['RNAChrom Name']}&organism=Homo+sapiens`"
                    target="_blank" rel="noopener noreferrer">
                    All {{ selectedRow["lncRNA"] }} ({{ selectedRow["RNAChrom Name"] }}) RNA contacts
                  </a>

                  <dropdown-menu :direction="right">
                    <template #trigger>
                      <a class="button violet">
                        {{ selectedRow["lncRNA"] }} ({{ selectedRow["RNAChrom Name"] }}) RNA contacts with the locus
                        ({{ selectedRow["Peak Id"] }})
                      </a>
                    </template>
                    <template #body>
                      <div class="menu-body">
                        <a class="button" v-for="num in [1000, 5000, 10000, 25000, 50000, 100000]" :key="num"
                          :href="`https://rnachrom2.bioinf.fbb.msu.ru/basic_graphical_summary_dna_filter?locus=${selectedRow['Peak Chr']}:${Math.max(parseInt(selectedRow['Peak Start']) - num, 0)}-${parseInt(selectedRow['Peak End']) + num}&name=${selectedRow['RNAChrom Name']}&organism=Homo+sapiens&rnaID=${selectedRow['RNAChrom ID']}`"
                          target="_blank" rel="noopener noreferrer">
                          &pm; {{ num }}
                        </a>
                      </div>
                    </template>
                  </dropdown-menu>
                  <a class="button violet"
                    :href="`https://rnachrom2.bioinf.fbb.msu.ru/from_dna?locus=${selectedRow['Peak Chr']}:${selectedRow['Peak Start']}-${selectedRow['Peak End']}&organism=Homo+sapiens`"
                    target="_blank" rel="noopener noreferrer">
                    > All RNAs that have contacts with the locus ({{
            selectedRow["Peak Id"]
          }})
                  </a>
                </div>
                <span v-else>No selected row</span>
              </template>
            </dropdown-menu>
          </span>
        </div>
        <MyTable ref="resultsTable" :max="15" :selectable="true" v-if="results" :data="getTable.table || results.table"
          v-on:update:selectedRow="selectedRow = $event" />
      </div>
    </section>
    <Loader />
    <MyFooter />
  </main>
</template>
<script>
import MyHeader from "@/components/MyHeader";
import MyFooter from "@/components/MyFooter";
import MyTable from "@/components/MyTable";
import Loader from "@/components/Loader";
import axios from "axios";

export default {
  name: "result",
  components: {
    MyHeader,
    MyTable,
    Loader,
    MyFooter,
  },
  data() {
    return {
      results: null,
      link: null,
      selectedRow: null,
    };
  },
  computed: {
    getTable() {
      return this.$store.getters.getResults;
    },
  },
  methods: {
    getRow() {
      console.log(this.selectedRow);
      return this.selectedRow;
    },

    async download() {
      this.$store.dispatch("setLoader", true);
      let query = `/lncrna/api/v1/download`;
      let searchData = await this.$store.getters.getSearchData;
      axios
        .post(query, JSON.stringify(searchData), {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/csv",
          },
          timeout: 1000 * 60 * 60 * 24 * 7,
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Table.csv"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .finally(() => {
          this.$store.dispatch("setLoader", false);
        });
    },
  },
  async mounted() {
    this.$store.dispatch("setLoader", true);
    this.results = await this.$store.getters.getResults;
    this.$store.dispatch("setLoader", false);
  },
};
</script>

<style>
.v-dropdown-menu__trigger a.button {
  margin-bottom: 0;
}

.v-dropdown-menu__body a.button {
  margin-bottom: 0.7em;
}

.menu-body {
  display: flex;
  flex-direction: column;
}

.top-menu>* {
  margin-left: 1rem;
}

.v-dropdown-menu {
  position: relative;
  display: inline-block;
}

.v-dropdown-menu__trigger {
  position: relative;
}

.v-dropdown-menu__container {
  position: absolute;
  top: 100%;
  bottom: auto;
  min-width: 230px;
  max-width: 100%;
  overflow: visible;
  background-color: #fff;
  border: 1px solid #ddd;
}

.v-dropdown-menu--dropup .v-dropdown-menu__container {
  top: auto;
  bottom: 100%;
}

.v-dropdown-menu--direction-left .v-dropdown-menu__container {
  left: 0;
}

.v-dropdown-menu--direction-center .v-dropdown-menu__container {
  left: 50%;
  transform: translateX(-50%) translateY(0);
}

.v-dropdown-menu--direction-right .v-dropdown-menu__container {
  right: 0;
}

.v-dropdown-menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.v-dropdown-menu .default-enter-active {
  transition: all 0.2s ease;
}

.v-dropdown-menu .default-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.v-dropdown-menu .default-enter,
.v-dropdown-menu .default-leave-to {
  transform: translateY(12px);
  opacity: 0;
}

.v-dropdown-menu--mode-hover .default-enter,
.v-dropdown-menu--mode-hover .default-leave-active {
  transition-delay: 0.4s;
}

.v-dropdown-menu--dropup .default-enter,
.v-dropdown-menu--dropup .default-leave-to {
  transform: translateY(-12px);
}

.v-dropdown-menu--dropup.v-dropdown-menu--direction-center .default-enter,
.v-dropdown-menu--dropup.v-dropdown-menu--direction-center .default-leave-to {
  transform: translateX(-50%) translateY(-12px);
}

.v-dropdown-menu--direction-center .default-enter,
.v-dropdown-menu--direction-center .default-leave-to {
  transform: translateX(-50%) translateY(12px);
}
</style>
